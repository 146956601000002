/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          var $hamburger = $(".hamburger");
          $hamburger.on("click", function(e) {
            $hamburger.toggleClass("is-active");
            // Do something else, like open/close menu
          });

        $("input[type=file]").after('<span class="upload">Choose File</span>');

          $('a').each(function() {
              var a = new RegExp('/' + window.location.host + '/');
              if(!a.test(this.href)) {
                  $(this).click(function(event) {
                      event.preventDefault();
                      event.stopPropagation();
                      window.open(this.href, '_blank');
                  });
              }
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
/* ========================================================================
 new js
 * ======================================================================== */
jQuery(window).ready(function() {

    //check_alert();

    function community_hide_all() {
        jQuery('.community .icon-show').show();
        jQuery('.community .icon-close').hide();
        jQuery('.community .community-container').hide();
        jQuery('.community .show-detail').removeClass('active');
    }

    function community_hide_one(id) {
        jQuery('#community-'+id+'-title').removeClass('active');
        jQuery('#community-'+id+'-title .icon-show').show();
        jQuery('#community-'+id+'-title .icon-close').hide();
        jQuery('#community-'+id+'-content').hide(500);
    }

    function community_show(id) {
        jQuery('#community-'+id+'-title').addClass('active');
        jQuery('#community-'+id+'-title .icon-show').hide();
        jQuery('#community-'+id+'-title .icon-close').show();
        jQuery('#community-'+id+'-content').show(500);
    }

    jQuery('.community .show-detail').click(function(e) {
        e.preventDefault();
        var getId = jQuery(this).data('id');
        if(jQuery('#community-'+getId+'-content').is(":visible")) {
            community_hide_one(getId);
        }
        else {
            community_show(getId);
        }
    });

    community_hide_all();

});